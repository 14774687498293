import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_SENTRY_DSN, PUBLIC_ENVIRONMENT } from "$env/static/public";
import { ErrorCodes } from "$lib/utils/errorCodes";
import { LoggingService } from "$lib/logger";
import { FeatureFlagStore } from "@stores/cms/feature-flag-store";
import { startupFinished } from "@stores/cms/startup-store";
import { sentryBeforeSend } from "$lib/sentry";

// hacky workaround due to s.th. like https://github.com/getsentry/sentry-javascript/issues/8377
(window.process as any) = {
    env: {
        NODE_DEBUG: false,
    },
};

const startup = async () => {
    try {
        await FeatureFlagStore.initialize();
        const sentry_enabled = FeatureFlagStore.get().sentry;
        Sentry.init({
            tunnel: "/api/sentry-tunnel",
            dsn: PUBLIC_SENTRY_DSN,
            environment: PUBLIC_ENVIRONMENT,
            sampleRate: sentry_enabled ? 1 : 0,
            tracesSampleRate: sentry_enabled ? 0.1 : 0,
            // Wont record any replays
            replaysSessionSampleRate: 0,
            // Will automatically buffer last 60 seconds and only send a replay on ERROR.
            replaysOnErrorSampleRate: sentry_enabled ? 1 : 0,
            integrations: [Sentry.replayIntegration({ useCompression: false }), LoggingService.sentryLogIntegration],
            beforeSend: sentryBeforeSend,
        });
    } catch (err) {
        LoggingService.error("could not initialize sentry on the client", ErrorCodes.SentryInit);
    }
};

startup().then(() => {
    startupFinished.set(true);
});

export const handleError = ({ error }) => {
    LoggingService.error(error);
};
